<template>
  <a-card :bordered="false">
    <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
      <a-form-item label="端口" name='type'>
        <a-select class='m-b-15' v-model:value="search.port" :style="{width:'150px'}" placeholder="请选择">
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="1">门店端</a-select-option>
          <a-select-option :value="2">经销商端</a-select-option>
          <a-select-option :value="4">在线订货</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="关键词" name='keywords'>
        <a-input style="width: 270px" v-model:value="search.keyword"  placeholder="请输入经销商/门店名称进行搜索"/>
      </a-form-item>
      <a-form-item label="品牌商" name='brand_name'>
        <a-input style="width: 270px" v-model:value="search.brand_name"  placeholder="请输入品牌商名称进行搜索"/>
      </a-form-item>
      <a-form-item label="反馈者" name='feedback_name'>
        <a-input style="width: 270px" v-model:value="search.feedback_name"  placeholder="请输入反馈者进行搜索"/>
      </a-form-item>
      <a-form-item label="" name='feedback_type' >
        <a-select v-model:value="search.feedback_type" :style="{width:'150px'}" placeholder="请选择">
          <a-select-option value="">全部报反馈类型</a-select-option>
          <a-select-option :value="1">产品建议</a-select-option>
          <a-select-option :value="2">营销建议</a-select-option>
          <a-select-option :value="3">其他建议</a-select-option>
          <a-select-option :value="4">功能故障</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="" name='feedback_status' >
        <a-select v-model:value="search.feedback_status" :style="{width:'150px'}" placeholder="请选择">
          <a-select-option value="">全部状态</a-select-option>
          <a-select-option :value="0">普通反馈</a-select-option>
          <a-select-option :value="1">星标反馈</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="反馈时间" name='time'>
        <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" htmlType='submit'>搜索</a-button>
      </a-form-item>
    </a-form>
    <a-table :pagination='false' :loading='listLoading' rowKey='id'  :columns='columns' :data-source='data' bordered class="tableFixBox" :scroll="{ x: 'max-content'}">
      <template v-slot:action="{ record }">
        <a v-has:feedbackMore="()=> {$router.push('/feedback/list/more?id='+record.id)}"  href="javascript:;" >查看详情</a>
      </template>
    </a-table>
    <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
  </a-card>
</template>
<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { message,Modal } from "ant-design-vue";
  import { $iscode } from "@/utils/app";
  import { getFeedbackList } from "@/api/index";
  // 初始化默认筛选项数值
  let defSearch = {
    port:'',
    keyword:'',
    feedback_name:'',
    feedback_type:'',
    feedback_status:'',
    start_time:'',
    end_time:'',
    brand_name:'',
    page:1,
    limit:10
  }
  export default {
    components: { },
    setup(props) {
      const search = ref({ ...defSearch });
      const state = reactive({
        listLoading:false,
        data:[],
        activeKey:'',
        time:[],
        total:0,
        columns:[
          {
            title: "品牌商",
            dataIndex: "brand_name",
            key: "brand_name",
          },
          {
            title: "端口",
            dataIndex: "port_name",
            key: "port_name",
          },
          {
            title: "经销商/门店名称",
            dataIndex: "store_name",
            key: "store_name",
          },
          {
            title: "反馈者",
            dataIndex: "feedback_name",
            key: "feedback_name",
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            key: "phone",
          },
          {
            title: "反馈类型",
            dataIndex: "type_name",
            key: "type_name",
          },
          {
            title: "反馈时间",
            dataIndex: "create_time",
            key: "create_time",
          },
          {
            title: "状态",
            dataIndex: "status_name",
            key: "status_name",
          },
          {
            title: "操作",
            dataIndex: "action",
            key: "action",
            fixed:"right",
            width:120,
            slots: { customRender: "action" }
          },
        ]
      });
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, limit) => {
        search.value.page = 1;
        search.value.limit = limit;
        initData(search.value);
      };
      // 时间筛选
      const onChange = (e) => {
        search.value.start_time = e[0]
        search.value.end_time = e[1]
      }
      //获取列表
      const initData = async (values) => {
        state.listLoading = true;
        try {
          let res = await getFeedbackList(values).then(res => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            state.data = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.msg);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      onMounted(() => {
        initData(search.value);
      });
      return {
        search,
        ...toRefs(state),
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        onChange
      };
    },
  };
</script>